import React from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import Addblog from './Addtestmonials';
import Profile from './Studydestination';
import Layout from '../Component/Layout';
import Updatesection from './Updatesection';
import Proffestionaldestination from './Proffestionaldestination';
import Studydestination from './Studydestination';
import Partneruniversity from './Partneruniversity';


const Clientsession = () => {
  return (
    <Layout>
    
      <Tabs defaultActiveKey="Study Destination" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="Study Destination" title="Study Destination">
          <Studydestination />
        </Tab>
        

        <Tab eventKey="Testmonials" title="Testmonials">
        <Updatesection />
      </Tab>
       
      <Tab eventKey="Proffestionaldestination" title="Professional destination">
      <Proffestionaldestination />
    </Tab>

    <Tab eventKey="Partneruniversity" title="Partnering Universities">
      <Partneruniversity />
    </Tab>

      </Tabs>
    
    </Layout>
  );
};

export default Clientsession;
