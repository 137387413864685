import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Updatesection() {
  const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');
  const [testmoniallist, setTestmoniallist] = useState([]);

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');

    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

  useEffect(() => {
    TestList();
  }, []);

  const TestList = async () => {
    try {
      const response = await fetch('https://lunarsenterprises.com:5003/studyabroad/list/testmonials');
      const data = await response.json();
      if (data && data.data) {
        setTestmoniallist(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (t_id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this Testimonial?');
    if (confirmDelete) {
      try {
        const headers = {
          headers: {
            api_key: userApiKey,
            user_id: userId,
          },
        };
        await axios.post('https://lunarsenterprises.com:5003/studyabroad/delete/testmonials', { t_id }, headers);
        setTestmoniallist((prevList) => prevList.filter((item) => item.t_id !== t_id));
        toast.success('Testimonial deleted successfully!');
      } catch (error) {
        console.error('Error deleting testimonial:', error);
        toast.error('Error deleting testimonial. Please try again.');
      }
    } else {
      toast.info('Testimonial deletion cancelled.');
    }
  };

  return (
    <div className='mb-5'>
      <div>
        <Button href="/Addtestmonials" className="addprofile">
          <FontAwesomeIcon icon={faPlus} /> Add Testimonial
        </Button>
      </div>
      
        <Row className="gy-4">
          {testmoniallist?.map((card, index) => (
            <Col key={index} xs={12} sm={6} md={6} lg={4}>
              <Card className="heightadjust">
                <Card.Img
                  variant="top"
                  className="card-img-contain"
                  src={`https://lunarsenterprises.com:5003/${card.t_image}`}
                  onError={(e) => { e.target.src = 'https://via.placeholder.com/150'; }}
                />
                <Card.Body className="bodycolor">
                  <Card.Title>{card.t_name}</Card.Title>
                  <Card.Text>{card.t_visa}, {card.t_place}</Card.Text>
                  <Card.Subtitle>{card.t_description}</Card.Subtitle>
                  <div className='d-flex mt-3'>
                    <Link to={`/editestmonials/${card.t_id}`} className="btn btn-primary">
                      <FontAwesomeIcon icon={faPenToSquare} /> Edit Testimonial
                    </Link>
                    <Button
                      variant="danger"
                      className="deletebuttonpro"
                      onClick={() => handleDelete(card.t_id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
   
      <ToastContainer />
    </div>
  );
}

export default Updatesection;
