import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Layout from "../../Component/Layout";
import { useNavigate } from "react-router-dom";

// Function to determine status class
const getStatusClass = (status) => {
  switch (status) {
    case "Ongoing":
      return "status-ongoing";
    case "Pending":
      return "status-pending";
    case "Success":
      return "status-success";
    default:
      return "";
  }
};

// Main component
const ClientList = () => {
  const [notlist, setNotList] = useState([]); // Notification list state
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [filteredNotList, setFilteredNotList] = useState([]); // Filtered list based on search

  const navigate = useNavigate(); // Correct hook for navigation

  // Fetch all notifications on component mount
  useEffect(() => {
    Notification();
  }, []);

  // Function to fetch all notifications
  const Notification = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5003/studyabroad/service/list",
        { u_id: "" }
      );
      if (response.status === 200 && response.data.result) {
        const fetchedData = response.data.data || [];
        setNotList(fetchedData);
        setFilteredNotList(fetchedData);
      } else {
        console.warn("No notifications found:", response.data.message);
        setNotList([]);
        setFilteredNotList([]);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotList([]);
      setFilteredNotList([]);
    }
  };

  // Filter notifications based on the search term
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredNotList(notlist); // Show full list if no search term
    } else {
      const filtered = notlist.filter((person) => {
        const userId = person.u_id ? String(person.u_id) : ""; // Ensure u_id is treated as a string
        return userId.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredNotList(filtered);
    }
  }, [searchTerm, notlist]);

  // Handle search input and update search term
  const handleSearch = (e) => {
    setSearchTerm(e.target.value); // Update search term on input change
  };

  // Function to handle "View" button click
  const handleView = (s_id) => {
    navigate(`/Clientupdate/${s_id}`); // Dynamic route with s_id as the parameter
  };

  return (
    <Layout>
      <div className="container cont">
        <h1>Notification</h1>

        {/* Search Bar */}
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by User ID..."
            value={searchTerm}
            onChange={handleSearch} // Update search term on input change
            className="search-bar"
          />
        </div>

        {/* Table of Notifications */}
        <div className="notification-table mt-4">
          {filteredNotList.length > 0 ? (
            <Table striped bordered hover responsive="sm">
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>User Name</th>
                  <th>Service</th>
                  <th>Message</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredNotList.map((person) => (
                  <tr key={person.u_id}>
                    <td>{person.u_id}</td>
                    <td>{person.user_name}</td>
                    <td>{person.s_name}</td>
                    <td>{person.s_message}</td>
                    <td>
                      <span
                        className={`status-badge ${getStatusClass(
                          person.s_status
                        )}`}
                      >
                        {person.s_status}
                      </span>
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleView(person.s_id)}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No results found for the given User ID.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ClientList;
