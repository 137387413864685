import React, { useEffect, useState } from 'react';
import Layout from '../Component/Layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Imagefolder from '../img/Imagefolder.jpg';

import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';

function Addprofile() {

  const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');

  useEffect(() => {
    
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');

  
    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    description: '',
    image: null,
  });

  const [preview, setPreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      const file = files[0];
      setFormData({ ...formData, image: file });
      setPreview(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        api_key: userApiKey,
        user_id: userId,
      };

      const data = new FormData();
      data.append('name', formData.name);
      data.append('designation', formData.designation);
      data.append('description', formData.description);
      if (formData.image) {
        data.append('image', formData.image);
      }

      const response = await axios.post(
        'https://lunarsenterprises.com:5005/jyothisham/add/team',
        data,
        { headers }
      );

      console.log(response.data);
      toast.success('Profile added successfully!'); // Show success message

      // Optionally reset formData or redirect
      setFormData({ name: '', designation: '', description: '', image: null });
      setPreview(null); // Clear image preview

    } catch (error) {
      console.error('Error adding team profile:', error); // handle error response
      toast.error('Error adding profile. Please try again.'); // Show error message
    }
  };

  return (
    <Layout>
      <Container>
        <Row className='centrerstylr'>
          <Col md={6} className='p-3'>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formImage" className="mt-3">
                <div className='centr'>
                  {preview ? (
                    <img src={preview} alt="Selected Image" className="img-fluid mt-3" />
                  ) : (
                    <img src={Imagefolder} alt="Default Image" className="img-fluid mt-3" />
                  )}
                </div>
                <Form.Control
                  type="file"
                  name="image"
                  className="mt-4"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-2" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formDesignation" className="mt-3">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  type="text"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formDescription" className="mt-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Save
              </Button>
            </Form>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </Layout>
  );
}

export default Addprofile;
