import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Notification.css"; // Add your own styles
import Layout from "../../Component/Layout";
import axios from "axios";

const Notification = () => {
  const [newPerson, setNewPerson] = useState({
    email: "",
    name: "",
    subject: "",
    message: "",
    status: "Pending",
    phno: "",
  });

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPerson((prevPerson) => ({
      ...prevPerson,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleAddPerson = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5003/studyabroad/add/admin/service",
        newPerson
      );
      console.log(response);
      toast.success("User added successfully!");
      setNewPerson({
        email: "",
        name: "",
        subject: "",
        message: "",
        status: "Pending",
        phno: "",
      }); // Clear the form after submission
    } catch (error) {
      console.error("There was an error adding the user!", error);
      toast.error("Failed to add user. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="admin-container">
        <h1> Add New User</h1>

        {/* Form to add a new user */}
        <form onSubmit={handleAddPerson} className="add-person-form">
          <input
            type="text"
            name="name"
            value={newPerson.name}
            onChange={handleInputChange}
            placeholder="Name"
            required
          />
          <input
            type="email"
            name="email"
            value={newPerson.email}
            onChange={handleInputChange}
            placeholder="Email"
            required
          />
          <input
            type="text"
            name="phno"
            value={newPerson.phno}
            onChange={handleInputChange}
            placeholder="Number"
            required
          />
          <textarea
            name="message"
            value={newPerson.message}
            onChange={handleInputChange}
            placeholder="Message"
            required
          ></textarea>

          <label>Status:</label>
          <select
            name="status"
            value={newPerson.status}
            onChange={handleInputChange}
            required
          >
            <option value="Pending">Pending</option>
            <option value="Ongoing">Ongoing</option>
            <option value="Success">Success</option>
          </select>

          <label>Services (separated by commas):</label>
          <input
            type="text"
            name="subject"
            value={newPerson.subject}
            onChange={handleInputChange}
            placeholder="e.g. Flight Ticket, Foreign Admission, Job"
            required
          />

          <button type="submit">Add User</button>
        </form>

        {/* Toast notification container */}
        <ToastContainer />
      </div>
    </Layout>
  );
};

export default Notification;
