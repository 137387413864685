import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Partneruniversity() {

    const [userId, setUserId] = useState('');
    const [userApiKey, setUserApiKey] = useState('');
  
    useEffect(() => {
      
      const storedUserId = localStorage.getItem('user_id');
      const storedUserApiKey = localStorage.getItem('user_api_key');
  
    
      if (storedUserId) {
        setUserId(storedUserId);
      }
      if (storedUserApiKey) {
        setUserApiKey(storedUserApiKey);
      }
    }, []);

 


  

  const handleDelete = async (p_id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this Partnering Universities?');
    if (confirmDelete) {
      try {
        const headers = {
          headers: {
            api_key: userApiKey,
            user_id: userId,
          },
        };
        await axios.post('https://lunarsenterprises.com:5003/studyabroad/delete/partner', { p_id }, headers);
        setTestmoniallist((prevTeamList) => prevTeamList.filter((team) => team.p_id !== p_id));
        toast.success('Partnering Universities deleted successfully!');
      } catch (error) {
        console.error('Error deleting Partnering Universities:', error);
        toast.error('Error deleting Partnering Universities. Please try again.');
      }
    } else {
      toast.info('Partnering Universities deletion cancelled.');
    }
  };



  const [testmoniallist, setTestmoniallist] = useState([]);

  useEffect(() => {
    TestList();
  }, []);

  const TestList = async () => {
    try {
      const response = await fetch('https://lunarsenterprises.com:5003/studyabroad/list/partner');
      const data = await response.json();
      setTestmoniallist(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='mb-5'>
      <div>
        <Button href="/Addpartner" className="addprofile">
          <FontAwesomeIcon icon={faPlus} /> Add Partnering Universities
        </Button>
      </div>

   

     
        <Row className="gy-4">
          {testmoniallist?.map((card, index) => {
            
            return (
              <Col key={index} xs={12} sm={6} md={6} lg={4}>
                <Card className="heightadjust">
                  
                <Card.Img
                variant="top"
                className="card-img-contain"
                src={`https://lunarsenterprises.com:5003/${card.p_image}`}
                onError={(e) => { e.target.src = 'https://via.placeholder.com/150'; }}
              />
                 
                
                 
        
                    
                 
                   
                    <div className=' d-flex mt-3'>
                   
                    <Button
                      variant="danger"
                      className="  deletebuttonpro"
                      onClick={() => handleDelete(card.p_id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    </div>
                
                </Card>
              </Col>
            );
          })}
        </Row>
     
      <ToastContainer />
      
    </div>
  );
}

export default Partneruniversity;
