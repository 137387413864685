import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "../src/css/main.scss";
import "../src/css/bootstrap.min.css";
import Sidebar from "./Component/Sidebar";
import Header from "./Component/Header";
import SignIn from "./Screens/Signin";
import AppState from "./context/Context";
import Profile from "./Screens/Studydestination";
import TeamMemberProfile from "./Screens/TeamMemberProfile";
import Addprofile from "./Screens/Addprofile";
import Updatesection from "./Screens/Updatesection";
import Addservices from "./Screens/Addservices";
import Clientsession from "./Screens/Clientsession";
import Addtestmonials from "./Screens/Addtestmonials";
import Edittestmonials from "./Screens/Edittestmonials";
import Proffestionaldestination from "./Screens/Proffestionaldestination";
import AddProfessional from "./Screens/AddProfessional";
import Editprofessional from "./Screens/Editprofessional";
import Studydestination from "./Screens/Studydestination";
import AddstudyDestinaation from "./Screens/AddstudyDestination";
import Editstudydestination from "./Screens/Editstudydestination";
import Partneruniversity from "./Screens/Partneruniversity";
import Addpartner from "./Screens/Addpartner";
import Notification from "./Screens/Notification/Notification";
import ClientList from "./Screens/ClientList/ClientList";
import Clientupdate from "./Screens/Clientupdate/Clientupdate";


function Main() {
  const location = useLocation();
  const currPath = location.pathname;

  return (
    <div className="">
      {/* Sidebar and Header */}
      {currPath !== "/SignIn" && (
        <>
          <Sidebar />
          <Header />
        </>
      )}

      {/* Content */}
      <div className="">
        <Routes>
          <Route path="/" element={<Navigate to="/SignIn" />} />
          <Route path="/SignIn" element={<SignIn />} />
          
          <Route path="/Studydestination" element={<Studydestination />} />
          <Route path="/Proffestionaldestination" element={<Proffestionaldestination />} />
          <Route path="/AddProfessional" element={<AddProfessional />} />

          <Route path="/Client/session" element={<Clientsession />} />
          <Route path="/Addprofile" element={<Addprofile />} />
          <Route path="/Update/section" element={<Updatesection />} />
          <Route path="/Addtestmonials" element={<Addtestmonials />} />
          <Route path="/Add/services" element={<Addservices />} /> 
          <Route path="/AddstudyDestinaation" element={<AddstudyDestinaation />} /> 
          <Route path="/Partneruniversity" element={<Partneruniversity />} />
          <Route path="/Addpartner" element={<Addpartner />} />

          <Route path="/editestmonials/:t_id" element={<Edittestmonials />} /> 
          <Route path="/editprofessional/:p_id" element={<Editprofessional />} /> 


          <Route path="/editstudydestination/:s_id" element={<Editstudydestination />} /> 
          <Route path="/Clientupdate/:s_id" element={<Clientupdate />} /> 
          <Route path="/Notification" element={<Notification/>} />

          <Route path="/ClientList" element={<ClientList/>} />


          
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <AppState>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </AppState>
  );
}

export default App;
