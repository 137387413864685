import React, { useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import OTPInput from "react-otp-input";
import Logo from "../img/Logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from "../Component/Forgotpassword";

function SignIn() {
  const handleOtpClose = () => setShowOtp(false);
  const handleOtpOpen = () => setShowOtp(true);
  const [showOtp, setShowOtp] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const navigate = useNavigate();
  const passwordRef = useRef(null);

  const handleSignIn = () => {
    if (email.trim() === "" || password.trim() === "") {
      toast.error("Please enter valid credentials.");
    } else {
      navigate("/Profile");
      console.log("Signed in successfully!");
    }
  };

  const handleEmailKeyDown = (e) => {
    if (e.key === "Enter") {
      passwordRef.current.focus();
    }
  };

  // API login
  const handleSignInClick = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5003/studyabroad/login",
        {

          
            device_id:"fff",
            device_os:"ff",
            device_token:"fff",
           
            app_version:"ff",
        
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data;

      if (response.status === 200 && data.result) {
        console.log("Login successful:", data.user_id);
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        localStorage.setItem("user_api_key", data.user_api_key);
        localStorage.setItem("user_id", data.user_id);


        console.log("Email:", email);
        console.log("User ID:", data.user_id);
        console.log("User API Key:", data.user_api_key);
        
        navigate("/Client/session", { state: { user_id: data.user_id } });
        toast.success("Login successful!");

      
      } else {
        document
          .querySelectorAll("input")
          .forEach((input) => (input.style.border = "1px solid red"));
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Login failed:", error.response?.data?.message || error.message);
      toast.error("Login failed: " + (error.response?.data?.message || error.message));
    }
  };

const [otpverify, setotpverify] = useState()
  const Otpverification = async () =>{

    try {

      const body = {

        email :email,
        code :''


      }
      
    } catch (error) {
      
    }
  }

  return (
    <div className="login-main container-fluid">
      <ToastContainer />
      <div className="signinstyle">
        <img
          src={Logo}
          className="redlogo"
          alt="Red Logo"
          height="75px"
          width="200px"
        />
        <p
          className="mb-4"
          style={{
            color: "#24415f",
            fontWeight: "bold",
            fontSize: "40px",
            textAlign: "center",
          }}
        >
          Sign In
        </p>

        <div className="login-wrapper-inner">
          <div className="mt-4 input-wrapper-login">
            <div className="icondirection icon-input-login">
             
            </div>
            <input
              type="email"
              className="form-control login-inpu"
              placeholder="Enter User Name"
              aria-label="Enter User Name"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleEmailKeyDown}
              required
              autoComplete="email"
            />
          </div>
          <div className="mt-4 input-wrapper-login">
            <div className="icondirection icon-input-login">
           
            </div>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control "
              placeholder="Enter Password"
              aria-label="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              ref={passwordRef}
            />
            <div
              className="password-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          <div className="text-center mt-3">
            <a
              href="#"
              className="text-decoration-none text-black colorforgot"
              onClick={handleShow}
            >
              Forgot Your Password?
            </a>
            <div className="linestyless d-flex justify-content-center align-items-center mt-2"></div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button className="btnsign" onClick={handleSignInClick}>
              SIGN IN
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered"
        aria-labelledby="example-modal-sizes-title-lg"
      >
       
        <Modal.Body>
        <ForgotPassword/>
         
        </Modal.Body>
      
      </Modal>

      <Modal
        show={showOtp}
        onHide={handleOtpClose}
        dialogClassName="modal-dialog-centered"
        aria-labelledby="otp-modal-title"
      >

      
        <Modal.Header closeButton>
          <Modal.Title id="otp-modal-title">OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center mt-2">
          <OTPInput
            // value={otp}
            // onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              fontSize: "2rem",
              borderRadius: 4,
              border: "3px solid red",
            }}
          />

          <div>
            <div className="text-center mt-3">
              {/* {timer > 0 ? (
                <p>Resend OTP in {timer} seconds</p>
              ) : (
                <Button variant="link" onClick={handleResend}>
                  Resend OTP
                </Button>
              )} */}
            </div>
          </div>
        </Modal.Body>
        <ForgotPassword/>
        <Modal.Footer>
          <Button variant="primary" onClick={handleOtpClose}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SignIn;
