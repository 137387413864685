import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, useNavigate } from 'react-router-dom';


const Forgcodeassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [code, setcode] = useState('');
  const [password, setpassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://lunarsenterprises.com:5003/studyabroad/forgotpassword', { email });
      if (response.data.status) {
        toast.success(response.data.message);
        setStep(2);
        setTimer(60); // Set timer for 60 seconds
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error sending code. Please try again.');
    }
  };

  const handlecodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://lunarsenterprises.com:5003/studyabroad/emailverification', { email, code });
      if (response.data.result) {
        toast.success(response.data.message);
        setStep(3);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Invalid code. Please try again.');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match. Please try again.');
      return;
    }
    try {
      const response = await axios.post('https://lunarsenterprises.com:5003/studyabroad/changepassword', { email, password });
      if (response.data.result) {
        toast.success(response.data.message);
        navigate('/SignIn'); // Reset to first step after successful password reset
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error resetting password. Please try again.');
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timer]);

  const resendcode = async () => {
    try {
      const response = await axios.post('https://lunarsenterprises.com:5003/studyabroad/forgotpassword', { email });
      if (response.data.result) {
        toast.success(response.data.message);
        setTimer(60); // Reset timer to 60 seconds
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error resending code. Please try again.');
    }
  };

  return (
    <div className="forgot-password">
      <ToastContainer />
      {step === 1 && (
        <form onSubmit={handleEmailSubmit}>
          <h2>Enter your email</h2>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
          />
          <button type="submit">Send code</button>
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handlecodeSubmit}>
          <h2>Enter the code sent to your email</h2>
          <input
            type="text"
            value={code}
            onChange={(e) => setcode(e.target.value)}
            required
            placeholder="code"
          />
          <div className="resend-otp">
            <button type="submit">Verify code</button>
            {timer > 0 ? (
              <span>Resend code in {timer}s</span>
            ) : (
              <button type="button" onClick={resendcode}>
                Resend code
              </button>
            )}
          </div>
        </form>
      )}
      {step === 3 && (
        <form onSubmit={handlePasswordSubmit}>
          <h2>Set a new password</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            required
            placeholder="New Password"
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            placeholder="Confirm Password"
          />
          <button type="submit">Reset Password</button>
        </form>
      )}
    </div>
  );
};

export default Forgcodeassword;
