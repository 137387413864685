import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../Component/Layout";
import axios from "axios";
import { useParams } from "react-router-dom";

const Clientupdate = () => {
  const { s_id } = useParams(); // Get s_id from the route params
  console.log(s_id, "s_ids_id");

  const [newPerson, setNewPerson] = useState({
    s_id: s_id,
    user_name: "",
    s_name: "",
    s_message: "",
    s_status: "Pending",
  });

  // Fetch data when the component mounts
  useEffect(() => {
    fetchPersonData();
  }, [s_id]);

  // Function to fetch data by s_id
  const fetchPersonData = async () => {
    try {
      const params = { s_id: s_id };
      const response = await axios.post(
        "https://lunarsenterprises.com:5003/studyabroad/service/view",
        params
      );

      if (response.data.result && response.data.data.length > 0) {
        const userData = response.data.data[0]; // Assuming you want the first item
        setNewPerson({
          s_id: s_id,
          user_name: userData.user_name, // Read-only
          s_name: userData.s_name,       // Read-only
          s_message: userData.s_message,
          s_status: userData.s_status,
        });
        toast.success("Data retrieved successfully!");
      } else {
        toast.error("No data found for the given s_id.");
      }
    } catch (error) {
      console.error("Error fetching user data!", error);
      toast.error("Failed to retrieve data. Please try again.");
    }
  };

  // Handle form input change (only for editable fields: s_message and s_status)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPerson((prevPerson) => ({
      ...prevPerson,
      [name]: value,
    }));
  };

  // Handle form submission to update only s_message and s_status
  const handleAddPerson = async (e) => {
    e.preventDefault();
    const { s_message, s_status } = newPerson; // Only send necessary fields
const bodyy=  {
    s_id: s_id,         // Send s_id for identifying the correct record
    s_message: s_message,
    s_status: s_status,
  }
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5003/studyabroad/update/admin/service",bodyy
      
      );
      console.log(response,'responseresponse');
      toast.success("User updated successfully!");
    } catch (error) {
      console.error("Error updating user!", error);
      toast.error("Failed to update user. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="admin-container">
        <h1>Edit User</h1>

        {/* Form to update user data */}
        <form onSubmit={handleAddPerson} className="add-person-form">
          <input
            type="text"
            name="user_name"
            value={newPerson.user_name}
            placeholder="Name"
            readOnly
          />
          <input
            type="text"
            name="s_name"
            value={newPerson.s_name}
            placeholder="Service Name"
            readOnly
          />
          <textarea
            name="s_message"
            value={newPerson.s_message}
            onChange={handleInputChange}
            placeholder="Message"
            required
          ></textarea>

          <label>Status:</label>
          <select
            name="s_status"
            value={newPerson.s_status}
            onChange={handleInputChange}
            required
          >
            <option value="Pending">Pending</option>
            <option value="Ongoing">Ongoing</option>
            <option value="Success">Success</option>
          </select>

          <button type="submit">Update User</button>
        </form>

        {/* Toast notification container */}
        <ToastContainer />
      </div>
    </Layout>
  );
};

export default Clientupdate;
