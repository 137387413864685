import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../Component/Layout';

const Edittestmonials = () => {
  const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');
  const { t_id } = useParams();
  const [testimonial, setTestimonial] = useState({
    t_place: '',
    t_description: '',
    t_image: '',
    t_visa:'',
    t_name:'',
     t_image:''

  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');
    if (storedUserId) setUserId(storedUserId);
    if (storedUserApiKey) setUserApiKey(storedUserApiKey);
  }, []);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          `https://lunarsenterprises.com:5003/studyabroad/list/testmonials`,
          {
            headers: {
              api_key: userApiKey,
              user_id: userId,
            }
          }
        );

        console.log('Fetched testimonials:', response.data.data);

        // Convert p_id to number for comparison
        const id = Number(t_id);
        const filteredTestimonial = response.data.data.find(item => item.t_id === id);

        if (filteredTestimonial) {
          setTestimonial(filteredTestimonial);
        } else {
          console.error('Testimonial not found:', id);
          toast.error('Testimonial not found.');
        }
      } catch (error) {
        console.error('Error fetching testimonials:', error);
        toast.error('Failed to fetch testimonials. Please try again.');
      }
    };

    if (userId && userApiKey) {
      fetchTestimonials();
    }
  }, [t_id, userApiKey, userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTestimonial((prevTestimonial) => ({ ...prevTestimonial, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('t_id', t_id);
    formData.append('t_name', testimonial.t_name);
  
    formData.append('t_visa', testimonial.t_visa);
    formData.append('t_place', testimonial.t_place);
    formData.append('t_description', testimonial.t_description);
    

    if (file) {
      formData.append('t_image', file);
    } else {
      formData.append('t_image', testimonial.t_image);
    }

    try {
      const response = await axios.post(
        'https://lunarsenterprises.com:5003/studyabroad/edit/testmonials',
        formData,
        {
          headers: {
            api_key: userApiKey,
            user_id: userId,
          },
        }
      );

      if (response.data.result) {
        toast.success('testimonials updated successfully!');
      } else {
        toast.error(`Update failed: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error updating testimonials:', error);
      toast.error('Error updating testimonials. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
    {userApiKey}
      <Container className="edit-testimonial mt-5">
        <h2>Edit Testimonial</h2>
        <Form onSubmit={handleSubmit}>

        <Form.Group controlId="t_name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="t_name"
          value={testimonial.t_name}
          onChange={handleInputChange}
          required
        />
      </Form.Group>
        
          <Form.Group controlId="t_visa">
            <Form.Label>Visa</Form.Label>
            <Form.Control
              type="text"
              name="t_visa"
              value={testimonial.t_visa}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="t_place">
          <Form.Label>Place</Form.Label>
          <Form.Control
            type="text"
            name="t_place"
            value={testimonial.t_place}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

          <Form.Group controlId="t_description">
            <Form.Label className="mt-1">Description</Form.Label>
            <Form.Control
              type="text"
              name="t_description"
              value={testimonial.t_description}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="p_image">
            <Form.Label className="mt-3">Upload Image</Form.Label>
            {testimonial.t_image && !file && (
              <img
                src={`https://lunarsenterprises.com:5003/${testimonial.t_image}`}
                alt="Testimonial"
                className="img-fluid mb-3"
              />
            )}
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </Form.Group>
          <Button type="submit" variant="primary" disabled={loading} className="mt-3">
            {loading ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              'Update Testimonial'
            )}
          </Button>
        </Form>
        <ToastContainer />
      </Container>
    </Layout>
  );
};

export default Edittestmonials;
