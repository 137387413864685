import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import Layout from '../Component/Layout';

export default function AddstudyDestinaation() {
  const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');

    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

 
  const [place, setPlace] = useState(''); 

  const [image, setImage] = useState(null); 
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
   
 
    formData.append('place', place);

    if (image) {
      formData.append('image', image);
    }

    try {
      await axios.post('https://lunarsenterprises.com:5003/studyabroad/add/study/destination', formData, {
        headers: {
          api_key: userApiKey,
          user_id: userId,
        },
      });
      toast.success('Study Destination added successfully!');
     
      setPlace('');
     
      setImage(null);
    } catch (err) {
      toast.error('Error adding Study Destination.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container className="add-blog mt-5">
        <h2>Add Study Destination</h2>
        <Form onSubmit={handleSubmit}>
        


          <Form.Group controlId="place">
            <Form.Label className='mt-3'>Place</Form.Label>
            <Form.Control
              type="text"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
              required
            />
          </Form.Group>
       
          <Form.Group controlId="file">
            <Form.Label className='mt-3'>Upload Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </Form.Group>
          <Button type="submit" variant="primary" disabled={loading} className='mt-3'>
            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Add Study Destination'}
          </Button>
        </Form>
        <ToastContainer />
      </Container>
    </Layout>
  );
}
