import React from 'react'
import Layout from '../Component/Layout'

export default function Addservices() {
  return (
    <div>
    <Layout>
    Addservices
    </Layout>
    </div>
  )
}
