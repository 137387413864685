import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../Component/Layout';

const Editstudydestination = () => {
  const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');
  const { s_id } = useParams();
  const [testimonial, setTestimonial] = useState({
    s_place: '',
    s_image: '',
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');
    if (storedUserId) setUserId(storedUserId);
    if (storedUserApiKey) setUserApiKey(storedUserApiKey);
  }, []);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          `https://lunarsenterprises.com:5003/studyabroad/list/study/destination`,
          {
            headers: {
              api_key: userApiKey,
              user_id: userId,
            }
          }
        );

        console.log('Fetched Study destination:', response.data.data);

        const id = Number(s_id);
        const filteredTestimonial = response.data.data.find(item => item.s_id === id);

        if (filteredTestimonial) {
          setTestimonial(filteredTestimonial);
        } else {
          console.error('Study destination not found:', id);
          toast.error('Study destination not found.');
        }
      } catch (error) {
        console.error('Error fetching Study destination:', error);
        toast.error('Failed to fetch Study destination. Please try again.');
      }
    };

    if (userId && userApiKey) {
      fetchTestimonials();
    }
  }, [s_id, userApiKey, userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTestimonial((prevTestimonial) => ({ ...prevTestimonial, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('s_id', s_id);
    formData.append('s_place', testimonial.s_place);

    if (file) {
      formData.append('s_image', file);
    }

    try {
      const response = await axios.post(
        'https://lunarsenterprises.com:5003/studyabroad/edit/study/destination',
        formData,
        {
          headers: {
            api_key: userApiKey,
            user_id: userId,
            'Content-Type': 'multipart/form-data'
          },
        }
      );

      if (response.data.result) {
        toast.success('Professional updated successfully!');
        setTestimonial((prev) => ({ ...prev, s_image: response.data.updatedImagePath }));
      } else {
        toast.error(`Update failed: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error updating Professional:', error);
      toast.error('Error updating Professional. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container className="edit-testimonial mt-5">

      {userApiKey}:{userId}
        <h2>Edit Study Destination</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="s_place">
            <Form.Label>Place Name</Form.Label>
            <Form.Control
              type="text"
              name="s_place"
              value={testimonial.s_place}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        
          <Form.Group controlId="s_image">
            <Form.Label className="mt-3">Upload Image</Form.Label>
            {testimonial.s_image && !file && (
              <img
                src={`https://lunarsenterprises.com:5003/${testimonial.s_image}`}
                alt="Testimonial"
                className="img-fluid mb-3"
              />
            )}
            <Form.Control
              type="file"
              
              onChange={handleFileChange}
            />
          </Form.Group>
          <Button type="submit" variant="primary" disabled={loading} className="mt-3">
            {loading ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              'Update Study Destination'
            )}
          </Button>
        </Form>
        <ToastContainer />
      </Container>
    </Layout>
  );
};

export default Editstudydestination;
